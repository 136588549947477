/* global colors */
:root {
    --light: #d1d7d7;
    --dark:#202121;
    --light_cell: #96b3b3;
    --light_cell_border: rgba(29, 62, 62, 0.52);
    --dark_cell: #233037;
    --dark_cell_border: rgba(107, 146, 163, 0.52);
}


/* animation */
@media (prefers-reduced-motion: no-preference) {
    .logo {
        animation: Logo-spin infinite 5s ease-in-out;
    }
}

.logo {
    height: 150px;
    width: auto;
    background-color: transparent;
    background-image: none;
}

@keyframes Logo-spin {
    from {
        transform: rotate(272deg);
    }
    to {
        transform: rotate(632deg);
    }
}

/* global styling */
* {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: var(--light);
}

.background{
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(90deg, #2e4343, #775e41);
    background-size: max(100%, 100%);
}

button {
    background-color: var(--dark);
    color: var(--light);
    padding: 10px 15px;
    margin: 0;
    border-radius: 0;
    border: 0;
}

input {
    padding: 20px;
    border-radius: 5px;
    border: solid;
    width: 250px;
}



/* Navigation Bar */

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: transparent; /* Ensure background color is set */
    background-image: none;
}

.nav > div {
    background-color: transparent;
    background-image: none;
}

.nav div:last-child {
    justify-content: flex-end;
    background-image: none;
}

.nav-links a {
    background-image: none;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: var(--light);
    background-color: var(--dark);
    margin: 0 5px; /* Add margin to space out the links */
}

/* User Info in the top-right of the website */
.user-details-container {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Space out the elements */
    padding: 10px; /* Add padding if needed */
}

.user-info {
    display: flex;
    align-items: center; /* Align the info bubble and text vertically */
}

.user-info img {
    width: 20px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
    margin-right: 10px; /* Space between the bubble and text */
}

.user-name {
    margin-right: 20px; /* Space between name and logout link */
}

.logout-link a {
    text-decoration: none;
    color: var(--light); /* Adjust as needed */
    background-color: var(--dark); /* Adjust as needed */
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px; /* Adjust as needed */
}


/* Home Page Styles */

.home-container {
    text-align: center;
    padding: 2rem;
}

.home-header {
    margin-bottom: 2rem;
}

.home-header h1 {
    font-size: 3rem;
    color: var(--light);
}

.home-header p {
    font-size: 1.5rem;
    color: var(--light);
}

.home-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card {
    background-color: var(--dark);
    border: 1px solid var(--light);
    border-radius: 8px;
    padding: 2rem;
    margin: 1rem;
    width: 250px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: var(--light);
    color: var(--dark);
    padding: 2rem;
    border-radius: 8px;
    width: 800px;
    text-align: center;
}

.modal-content h2 {
    margin-top: 0;
    color: var(--dark);
}
.modal-content p {
    color: var(--dark);
    white-space: pre-line;
}

.modal-content button {
    background-color: var(--dark);
    color: var(--light);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: var(--dark);
}

/* Google Login Page */
.google-login-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
}

.google-login-button button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}