
.projects-page {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    background-color: transparent;
}

.project-section {
    background: var(--dark);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-section:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.project-section h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: var(--light);
}

.project-section p {
    justify-content: space-around;
}

.project-content {
    font-size: 1rem;
    color: var(--light);
}

/* Button styles with animations */
.button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}
