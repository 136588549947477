.minesweeper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 20px;
    box-sizing: border-box;
}

.game-panel {
    display: flex;
    align-items: start;
    justify-content: normal;
    gap: 50px; /* Adjust gap as needed */
    width: 100%;
    padding: 20px 20px 20px 500px;
    box-sizing: border-box;
}

.board {
    display: flex;
    gap: 2px;
    width: 80vh;
    height: auto;
}

.cell {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: var(--light_cell);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--light_cell_border);
    font-size: 18px;
    user-select: none; /* Prevent text selection */
    -webkit-user-select: none; /* For Safari */
    -moz-user-select: none; /* For Firefox */
    -ms-user-select: none; /* For IE and Edge */
}

.cell.revealed {
    background-color: var(--dark_cell);
    cursor: default;
    border: 1px solid var(--dark_cell_border);
}

.game-over-message {
    margin-top: 1rem;
    font-size: 24px;
    color: red;
    white-space: nowrap;
}

.options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem; /* Space between options */
    padding: 1rem;
    width: auto;
    height: auto;
}

.new-game-button {
    background-color: var(--dark);
    color: var(--light);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.new-game-button:hover {
    background-color: #0056b3;
}

.settings {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.settings label {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}

.input-field {
    padding: 0.25rem;
    border: 1px solid var(--dark_cell_border);
    border-radius: 0.25rem;
    width: 60px;
    text-align: center;
}

.pre-configured {
    display: flex;
    gap: 0.5rem;
}

.quick-option {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.quick-option:hover {
    background-color: #218838;
}