.info-bubble-container {
    position: relative;
    display: inline-block;
}

.info-bubble {
    position: absolute;
    width: 200px;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: #333;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    white-space: normal;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 12px;
}

.info-bubble::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

.info-circle{
    width: 20px;
    height: 20px;
}